
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    /* eslint-disable no-case-declarations */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import SharedLibs from 'wizarding-world-web-shared';
import { useUserContext } from '@contexts/user-context';
import useCountry from '@hooks/useCountry';
import { DATA_ROW_CONTENTFUL_ID } from '@hooks/useVerticalIndex';
import HeadMeta from '@components/HeadMeta';
import Layout from '@components/MyLayout';
import SegmentPage from '@components/SegmentPage';
import ShopRow from '@components/ShopRow';
import Hero from '@components/DynamicComponents/HomeHero';
import Countdown from '@components/Countdown';
import MultiTilePromo from '@components/DynamicComponents/MultiTilePromo';
import FlexibleContainer from '@components/DynamicComponents/FlexibleContainer';
import FeaturedQuizList from '@/src/components/DynamicComponents/FeaturedQuizList';
import UserIdentityChecklist from '@components/DynamicComponents/UserIdentityChecklist';
import FlexibleCarousel from '@components/DynamicComponents/FlexibleCarousel';
import FullWidthBanner from '@components/DynamicComponents/FullWidthBanner';
import { HOMEPAGE_COMPONENT_TYPES as COMPONENTS, AGE_GATE_IS_UNDERAGE } from '@constants/index';
import { useGlobalState as GenericPageGlobalState } from '../../globalStates/GenericPageState';
import s from './HomeDynamic.module.scss';
const propTypes = {
    shop: PropTypes.shape({}),
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    pageData: PropTypes.shape({
        contentSlots: PropTypes.array,
        searchTitle: PropTypes.string,
        searchDescription: PropTypes.string,
        openGraphTitle: PropTypes.string,
        openGraphDescription: PropTypes.string,
        entryTitle: PropTypes.string,
        analyticsName: PropTypes.string,
        externalId: PropTypes.string,
        contentfulId: PropTypes.string,
        contentTypeId: PropTypes.string,
        revision: PropTypes.number,
        openGraphImage: PropTypes.shape({}),
        twitterCardImage: PropTypes.shape({}),
        theme: PropTypes.string,
        updatedAt: PropTypes.string
    }),
    patronusData: PropTypes.array
};
const defaultPageData = {
    entryTitle: '',
    contentSlots: []
};
const HomeDynamic = ({ metaTitle = null, metaDescription = null, pageData = defaultPageData, patronusData = null, }) => {
    const { getCookie } = SharedLibs.utils.cookie;
    const ageGateIsUnderage = getCookie(AGE_GATE_IS_UNDERAGE) === 'true';
    const { profile, loadingProfile, isLoggedIn } = useUserContext();
    const { country, loading: loadingCountry } = useCountry('');
    const [, setComponentOrder] = GenericPageGlobalState('componentOrder');
    const [, setOnVerticallyIndexedPage] = GenericPageGlobalState('onVerticallyIndexedPage');
    const ecomAgeGateEnabled = process.env.REACT_APP_ECOM_AGE_GATE_ENABLED === 'TRUE';
    const pageName = 'Wizarding World Home';
    const { contentSlots } = pageData;
    const geotargetCheckingEnabled = contentSlots.some((content) => content.contentTypeId === COMPONENTS.GEOTARGETED_CONTENT);
    let rowCount = 1;
    useEffect(() => {
        setOnVerticallyIndexedPage(true);
        return () => {
            setOnVerticallyIndexedPage(false);
            setComponentOrder([]);
        };
    }, [setComponentOrder, setOnVerticallyIndexedPage]);
    const renderClassNames = (index, length) => {
        return [
            s.row,
            index === 0 ? s.rowFirst : s[`row${rowCount % 6}`],
            index === length - 1 ? s.rowLast : '',
        ];
    };
    const renderRow = (content, index) => {
        if (index !== 0 && content.contentTypeId !== COMPONENTS.FULL_WIDTH_PROMO) {
            rowCount += 1;
        }
        const analyticsParams = {
            rootContentfulId: content?.contentfulId,
            rootContentTypeId: content?.contentTypeId,
            rootEntryTitle: content?.entryTitle
        };
        switch (content.contentTypeId) {
            case COMPONENTS.PRIMARY_HERO: {
                return <Hero {...content}/>;
            }
            case COMPONENTS.MULTI_TILE_PROMO: {
                return <MultiTilePromo {...content}/>;
            }
            case COMPONENTS.FLEXIBLE_CONTAINER: {
                return <FlexibleContainer {...content} analyticsParams={analyticsParams}/>;
            }
            case COMPONENTS.COUNTDOWN: {
                return <Countdown {...content}/>;
            }
            case COMPONENTS.FEATURED_QUIZ_LIST: {
                return <FeaturedQuizList {...content} analyticsParams={analyticsParams}/>;
            }
            case COMPONENTS.USER_IDENTITY_CHECKLIST: {
                return (<UserIdentityChecklist patronusData={patronusData} analyticsParams={analyticsParams} header={content?.header} housePercentages={{
                        gryffindor: content?.gryffindorPercentage || 32,
                        hufflepuff: content?.hufflepuffPercentage || 40,
                        ravenclaw: content?.ravenclawPercentage || 12,
                        slytherin: content?.slytherinPercentage || 16
                    }}/>);
            }
            case COMPONENTS.FLEXIBLE_CAROUSEL: {
                return <FlexibleCarousel {...content} rowCount={(rowCount - 1) % 6}/>;
            }
            case COMPONENTS.FULL_WIDTH_PROMO: {
                return <FullWidthBanner {...content}/>;
            }
            case COMPONENTS.SHOP_ROW: {
                return <ShopRow shopData={content} pageName={pageName} location="home"/>;
            }
            default:
                return null;
        }
    };
    const shouldHideContent = useCallback((content) => {
        let conditions = [false];
        switch (content.contentTypeId) {
            case COMPONENTS.USER_IDENTITY_CHECKLIST:
                conditions = [
                    !loadingProfile,
                    isLoggedIn,
                    content.hideWhenComplete,
                    profile?.hogwartsHouse,
                    profile?.patronusName,
                    profile?.wand?.core,
                    profile?.avatar?.avatarId,
                    profile?.marketingOptInWW,
                ];
                break;
            case COMPONENTS.SHOP_ROW:
                // eslint-disable-next-line no-nested-ternary
                const underAgeUser = isLoggedIn
                    ? profile.underage
                    : ecomAgeGateEnabled
                        ? ageGateIsUnderage
                        : true;
                conditions = [underAgeUser];
                break;
            case COMPONENTS.PRIMARY_HERO: {
                const targetedHeroExists = contentSlots.some((contentSlot) => contentSlot.contentTypeId === COMPONENTS.GEOTARGETED_CONTENT &&
                    contentSlot.content.contentTypeId === COMPONENTS.PRIMARY_HERO &&
                    contentSlot?.countries?.includes(country));
                conditions = [geotargetCheckingEnabled, loadingCountry || targetedHeroExists];
                break;
            }
            case COMPONENTS.GEOTARGETED_CONTENT:
                conditions = [loadingCountry || !content?.countries?.includes(country)];
                break;
            default:
                break;
        }
        return conditions.every(Boolean);
    }, [
        contentSlots,
        profile,
        isLoggedIn,
        loadingProfile,
        country,
        loadingCountry,
        geotargetCheckingEnabled,
    ]);
    // Memoize the result of processing content slots
    const memoizedProcessedContentSlots = useMemo(() => {
        const processContentSlots = (rawContentSlots) => {
            // Filter out any content slots that should be hidden
            const filteredContentSlots = rawContentSlots.filter((content) => !shouldHideContent(content));
            // Move visible geotargeted content out of wrapper and into root level of the content slot
            const flattenedContentSlots = filteredContentSlots.reduce((acc, content) => {
                if (content.contentTypeId === COMPONENTS.GEOTARGETED_CONTENT) {
                    return [...acc, content.content];
                }
                return [...acc, content];
            }, []);
            // Ensure only one hero is present on the page
            const deDuplicatedContentSlots = flattenedContentSlots.reduce((acc, content) => {
                if (content.contentTypeId === COMPONENTS.PRIMARY_HERO &&
                    acc.some((c) => c.contentTypeId === COMPONENTS.PRIMARY_HERO)) {
                    return acc;
                }
                return [...acc, content];
            }, []);
            // Ensure a placeholder hero is always present.
            // This adds a fallback state for missing content, and ensures
            // the hero space is preserved while country loads for geotargeted heroes.
            if (!deDuplicatedContentSlots.some((content) => content.contentTypeId === COMPONENTS.PRIMARY_HERO)) {
                deDuplicatedContentSlots.unshift({
                    contentTypeId: COMPONENTS.PRIMARY_HERO,
                    contentfulId: 'placeholder-hero',
                    delayReveal: true
                });
            }
            return deDuplicatedContentSlots;
        };
        return processContentSlots(contentSlots);
    }, [contentSlots, shouldHideContent]);
    // This state definition is here to ensure that proccessedContentSlots is initialized with a non-empty
    // default value. This prevents screen flashing on the first render and preserves anchor link behavior.
    const [processedContentSlots, setProcessedContentSlots] = useState(memoizedProcessedContentSlots);
    useEffect(() => {
        setProcessedContentSlots(memoizedProcessedContentSlots);
        setComponentOrder(memoizedProcessedContentSlots.map((slot) => slot.contentfulId));
    }, [memoizedProcessedContentSlots, setComponentOrder]);
    return (<Layout location={pageName}>
      <HeadMeta canonicalUrl={'/'} title={metaTitle} description={metaDescription}/>
      <SegmentPage key={pageName} pageName={pageName}/>
      <div className={s.root}>
        {processedContentSlots.map((content, index) => {
            const additionalAttributes = {
                [DATA_ROW_CONTENTFUL_ID]: content.contentfulId
            };
            return (<div key={content.contentfulId} className={[...renderClassNames(index, processedContentSlots.length)].join(' ')} data-testid={`${content.contentTypeId}-${content.contentfulId}`}>
              {/* Used to link directly to a row position from an external source */}
              <span id={`homepage-row-${index + 1}`} data-testid={`homepage-row-${index + 1}`} className={s.anchorPosition}/>
              <div className={[
                    s.rowComponent,
                    /* istanbul ignore next */
                    content?.contentTypeId ? s[content.contentTypeId] : '',
                ].join(' ')} {...additionalAttributes}>
                {renderRow(content, index)}
              </div>
            </div>);
        })}
      </div>
    </Layout>);
};
HomeDynamic.propTypes = propTypes;
export default HomeDynamic;

    async function __Next_Translate__getStaticProps__1948f1560eb__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//HomeDynamic/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1948f1560eb__ as getStaticProps }
  